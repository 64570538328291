<template>
  <div class="signIn">
    <div @click="$router.go(-1)">
      <svg-icon class="backBtn" iconClass="calendarBack" />
    </div>

    <div class="content">
      <div class="reSigninBox">
        <div class="title">登录打卡</div>
        <div class="reSigninBtn" @click="reSignin" v-if="forgetSignDays">
          {{ renewalSignPrice }}金币/补签打卡
        </div>
      </div>
      <div class="whoringCard">
        <div class="whoringItem">
          <div class="whoringTitle">连续打卡</div>
          <div class="dayNum">
            <span class="red">{{ currentSignDays }}</span>
            天
          </div>
        </div>
        <div class="whoringItem">
          <div class="whoringTitle">需要打卡</div>
          <div class="dayNum">
            <span>{{ totalDays }}</span>
            天
          </div>
        </div>
        <div class="whoringItem">
          <div class="whoringTitle">忘记打卡</div>
          <div class="dayNum">
            <span>{{ forgetSignDays }}</span>
            天
          </div>
        </div>
        <div class="leftDom"></div>
        <div class="rightDom"></div>
      </div>
      <van-tabs
        v-model="currentMonth"
        class="calendarTab oepnX"
        background="rgba(0,0,0,0)"
        title-inactive-color="#999"
        title-active-color="#000"
        @change="calculateDays"
      >
        <van-tab v-for="index in 12" :key="index" :name="index">
          <template #title>
            <div>{{ index }}月</div>
          </template>
          <div class="calendarContent">
            <div class="week">
              <div class="weekItem" v-for="weekItem in header" :key="weekItem">
                {{ weekItem }}
              </div>
            </div>
            <div class="dayBox">
              <div
                class="lastDay"
                v-for="lastDay in lastMonthSurplusDayArray"
                :key="'lastDay' + lastDay"
              >
                {{ lastDay }}
              </div>
              <div
                class="currentDay"
                :class="checkDay(currentMonth, currentDay)"
                v-for="currentDay in currentMonthDayCount"
                :key="'currentDay' + currentDay"
              >
                <svg-icon
                  iconClass="true"
                  class="signIcon"
                  v-if="checkDay(currentMonth, currentDay) == 'true'"
                />
                <svg-icon
                  iconClass="false"
                  class="signIcon"
                  v-if="checkDay(currentMonth, currentDay) == 'false'"
                />
                {{ currentDay }}
              </div>
              <div
                class="nextDay"
                v-for="nextDay in nextMonthSurplusDayArray"
                :key="'nextDay' + nextDay"
              >
                {{ nextDay }}
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      <div class="tipTitle">温馨提示：</div>
      <div class="tipContent">
        • 连续登录打卡完成任务,获得全额返现奖励;
        <br />
        • 补签金额递增，依次递增为10/20/40 金币
        <br />
        • ✓为已打卡,ㄨ为未打卡
        <br />
        • 点击补签即可补齐打卡天数
      </div>
    </div>
    <van-overlay :show="isShow" z-index="2001">
      <div class="wrapper flex-center-center">
        <div class="content">
          <div class="title">温馨提示</div>
          <!-- <div class="line"></div> -->
          <p class="subTitle">您当前金币余额不足</p>
          <p class="tip">前往购买金币 返回继续操作</p>
          <div class="btnBox flex-center-between">
            <div class="btn" @click="isShow = false">稍后再说</div>
            <div class="btn" @click="$router.push('/rechargePage?t=gold')">
              前往购买
            </div>
          </div>
        </div>
      </div>
      <!-- <div
        style="
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
        "
      ></div> -->
    </van-overlay>
  </div>
</template>
<script>
import { queryUserInfo, reSigin } from "@/api/user";
import { setSessionItem } from "@/utils/longStorage";
export default {
  data() {
    return {
      userInfo: {},
      userWallet: {},
      isVip: false,
      code: "",
      exchangeCodeShow: false,
      loading: false,
      advsList: [],
      countdownSec: 0, // 新手倒计时时间 单位秒
      timeStr: "",
      timeoutTimer: null, // 倒计时计时器
      percentage: 0, // 打卡进度
      totalDays: 0, // 需要打卡天数
      currentSignDays: 0, // 连续打卡天数
      forgetSignDays: 0, // 忘记打卡天数
      isShow: false,
      activeMonth: 0,
      header: ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"],
      lastMonthSurplusDay: 0,
      lastMonthSurplusDayArray: [],
      nextMonthSurplusDay: 0,
      nextMonthSurplusDayArray: [],
      currentMonthDayCount: 0,
      currentYear: 0,
      currentMonth: 0,
      currentDate: 0,
      leapMonthDay: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      normalMonthDay: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      signedArr: [],
      startTime: "",
      endTime: "",
      renewalSignPrice: "",
    };
  },
  created() {
    this.queryUserInfo();
  },
  methods: {
    async queryUserInfo() {
      // let req = this.$route.query.uid ? {
      //   uid: this.$route.query.uid != this.$store.getters.userInfo.uid ? this.$route.query.uid : 0
      // } : {
      //   uid: 0
      // };
      let res = await this.$Api(queryUserInfo);
      if (res.code === 200) {
        this.userInfo = res.data;
        this.totalDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.totalDays || 0;
        this.currentSignDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.currentSignDays || 0;
        this.forgetSignDays =
          this.userInfo?.whoringCard?.detailInfo[0]?.forgetSignDays || 0;
        this.signedArr =
          this.userInfo?.whoringCard?.detailInfo[0]?.recordTime || [];
        this.startTime =
          this.userInfo?.whoringCard?.detailInfo[0]?.startTime || "";
        this.endTime = this.userInfo?.whoringCard?.detailInfo[0]?.endTime || "";
        this.renewalSignPrice =
          this.userInfo?.whoringCard?.detailInfo[0]?.renewalSignPrice || 0;
        // getInfo();
        const { year, month, date } = this.getCurrentDate();
        this.currentYear = year;
        this.currentMonth = month;
        this.currentDate = date;
        this.calculateDays();
        setSessionItem("userInfo", JSON.stringify(res.data));
        this.$store.commit("user/SET_USERINFO", res.data);
        // this.$store.dispatch("commit/SET_USERINFO", res.data);
      }
    },
    getCurrentDate() {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth() + 1;
      const date = d.getDate();
      return {
        year,
        month,
        date,
      };
    },
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    },
    getMonthFirstDay(year, month) {
      return new Date(year, month - 1, 1).getDay();
    },
    calculateDays() {
      this.lastMonthSurplusDay =
        this.getMonthFirstDay(this.currentYear, this.currentMonth) === 0
          ? 6
          : this.getMonthFirstDay(this.currentYear, this.currentMonth) - 1;
      this.currentMonthDayCount = this.isLeapYear(this.currentYear)
        ? this.leapMonthDay[this.currentMonth - 1]
        : this.normalMonthDay[this.currentMonth - 1];

      let prevMonthLastDate = 0;
      if (this.currentMonth === 1) {
        prevMonthLastDate = this.isLeapYear(this.currentYear - 1)
          ? this.leapMonthDay[this.leapMonthDay.length - 1]
          : this.normalMonthDay[this.normalMonthDay.length - 1];
      } else {
        prevMonthLastDate = this.isLeapYear(this.currentYear)
          ? this.leapMonthDay[this.currentMonth - 2]
          : this.normalMonthDay[this.currentMonth - 2];
      }
      this.nextMonthSurplusDay =
        42 - (this.lastMonthSurplusDay + this.currentMonthDayCount);
      const prevtemp = [];
      const nexttemp = [];
      for (
        let i = prevMonthLastDate - this.lastMonthSurplusDay + 1;
        i <= prevMonthLastDate;
        i++
      ) {
        prevtemp.push(i);
      }
      for (let i = 1; i <= this.nextMonthSurplusDay; i++) {
        nexttemp.push(i);
      }
      this.lastMonthSurplusDayArray = prevtemp;
      this.nextMonthSurplusDayArray = nexttemp;
    },

    async reSignin() {
      try {
        let req = {
          pid: this.userInfo.whoringCard.detailInfo[0].id,
        };
        let res = await this.$Api(reSigin, req);
        // console.log(res);
        if (res.code == 200) {
          this.$toast("补签成功");
          this.queryUserInfo();
        } else if (res.code == 8000) {
          this.isShow = true;
        } else {
          this.$toast(res.tip || "补签失败");
        }
      } catch (e) {
        console.log(e);
      }
    },

    checkDay(month, day) {
      let startMonth = new Date(this.startTime).getMonth() + 1;
      let startDay = new Date(this.startTime).getDate();
      let startYear = new Date(this.startTime).getFullYear();
      let endYear = new Date(this.endTime).getFullYear();
      let endMonth = new Date(this.endTime).getMonth() + 1;
      let endDay = new Date(this.endTime).getDate();
      let currentMonth = new Date().getMonth() + 1;
      let currentDay = new Date().getDate();
      let currentYear = new Date().getFullYear();
      let startTime = new Date(
        startYear + "/" + startMonth + "/" + startDay
      ).getTime();
      let endTime = new Date(endYear + "/" + endMonth + "/" + endDay).getTime();
      let currentTime = new Date(
        currentYear + "/" + currentMonth + "/" + currentDay
      ).getTime();
      let calendarDate = new Date(
        currentYear + "/" + month + "/" + day
      ).getTime();
      let result = this.signedArr.find((item) => {
        const date = new Date(item.signTime);
        const m = date.getMonth() + 1;
        const d = date.getDate();
        return month == m && day == d;
      });
      if (result) {
        return "false";
      } else if (
        calendarDate <= currentTime &&
        calendarDate >= startTime &&
        calendarDate <= endTime
      ) {
        return "true";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.signIn {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  background: linear-gradient(180deg, #94d6da 0%, #f4f4f4 35%);
  // background: #000;
  // background: url('../../../assets/images/png/signInBg.png');
  background-size: 100% 100%;
  overflow: hidden;
  // padding: 0 18px;
  box-sizing: border-box;
  position: relative;
  .backBtn {
    width: 20px;
    height: 20px;
    margin: 12px 0 24px 16px;
  }
  .reSigninBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;
    // margin: 16px 0 17px;
    .title {
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }
    .reSigninBtn {
      // width: 82px;
      padding: 0 14px;
      box-sizing: border-box;
      height: 32px;
      border-radius: 16px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      background: #94d6da;
    }
  }
  .content {
    height: calc(100vh - 56px);
    padding: 0 15px;
    box-sizing: border-box;
    color: #999;
    // background: #1a1a1a;
    .whoringCard {
      width: 100%;
      height: 105px;
      border-radius: 14px;
      margin-bottom: 18px;
      padding: 0 31px;
      box-sizing: border-box;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      .leftDom {
        position: absolute;
        width: 10px;
        height: 44px;
        border-radius: 5px;
        background: #e6e6e6;
        bottom: -31px;
        left: 20px;
        z-index: 2;
      }
      .rightDom {
        position: absolute;
        position: absolute;
        width: 10px;
        height: 44px;
        border-radius: 5px;
        background: #e6e6e6;
        bottom: -31px;
        right: 20px;
        z-index: 2;
      }
      .whoringItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        .whoringTitle {
          margin-bottom: 13.7px;
        }
        .dayNum {
          span {
            font-size: 22px;
            color: #666;
          }
        }
      }
    }
    .calendarTab {
      height: 350px;
      background: url("../../../assets/png/calendarBg.png");
      background-size: 100% 100%;

      // background: red;
      /deep/ .van-tabs__wrap {
        // border-radius: 13.7px 13.7px 0 0;
        // background: #1f2031;
        padding-top: 6px;
        box-sizing: border-box;
        height: 54px;
        border-bottom: 1px solid #e6e6e6;
      }
      /deep/ .van-tab__text {
        font-size: 16px;
      }
      /deep/ .van-tabs__line {
        background-color: #94d6da !important;
      }
      .calendarContent {
        width: 100%;
        height: 305px;
        padding: 20.5px 20.5px 0;
        box-sizing: border-box;
        .week {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-row-gap: 18px;
          grid-column-gap: 18px;
          font-size: 12px;
          text-align: center;
          color: #999;
          margin-bottom: 18px;
        }
        .dayBox {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-row-gap: 18px;
          grid-column-gap: 18px;
          font-size: 12px;
          text-align: center;
          color: #7f7f7f;
          .lastDay {
            color: #999;
            height: 18px;
            line-height: 18px;
            position: relative;
          }
          .currentDay {
            color: #333;
            height: 18px;
            line-height: 18px;
            position: relative;
          }
          .nextDay {
            color: #999;
            height: 18px;
            line-height: 18px;
            position: relative;
          }
          .signIcon {
            width: 14px;
            height: 14px;
            position: absolute;
            top: 2px;
            left: -8px;
          }
          .true {
            color: #00d6be;
          }
          .false {
            color: #fd0563;
          }
        }
      }
    }
    .tipTitle {
      margin-top: 20.54px;
      font-size: 12px;
      color: #fd0563;
    }
    .tipContent {
      font-size: 12px;
      margin-top: 10px;
      line-height: 26px;
    }
  }
  .red {
    color: #fc4162 !important;
  }
}
.wrapper {
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: #000;
  .content {
    width: 300px;
    height: 252px;
    padding: 24px 0 27px;
    box-sizing: border-box;
    border-radius: 10px;
    background: linear-gradient(180deg, #e5feff 0%, #fff 100%);
    //background-image: linear-gradient(to bottom, rgb(199, 255, 249), rgb(255, 255, 255));
    position: relative;
    text-align: center;
    .title {
      font-size: 20px;
      text-align: center;
      margin-bottom: 12px;
      color: #333;
      font-weight: 500;
      // color: rgba(0, 214, 190, 1);
    }
    .line {
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(78, 78, 78, 0) 0%,
        #4e4e4e 47.92%,
        rgba(78, 78, 78, 0) 100%
      );
      //background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    }
    .subTitle {
      font-size: 20px;
      margin-top: 43px;
      color: #333;
    }
    .tip {
      margin-top: 18px;
      margin-bottom: 26px;
      font-size: 14px;
      color: #333;
    }
    // p {
    //   font-size: 14px;
    //   line-height: 30px;
    //   text-align: center;
    // }
  }
  .btnBox {
    // width: 268px;
    // margin-top: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 9;
    .btn {
      width: 108px;
      height: 38px;
      //background: rgba(190, 190, 190, 1);
      background-color: #b4b4b4;
      color: #fff;
      // margin: 0 10px 0 0;
      border-radius: 38px;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .btn:nth-child(2) {
      width: 136px;
      height: 38px;
      background: #94d6da;
    }
  }
}
// /deep/ .van-overlay {
//   background: rgba(255, 255, 255, 1);
// }
</style>
